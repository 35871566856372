.noteworthy {
  height: 100vh;

  display: flex;
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgb(0, 0, 0, 0.8)),
    url("https://getwallpapers.com/wallpaper/full/c/7/8/539481.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: breath 50s linear infinite;
}
.container {
  width: 70%;
  margin: 5% auto;
}
.cardFrame {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5rem;
}

.card {
  padding: 2rem;
  background-color: rgb(0, 0, 0, 0.6);
  box-shadow: 0px 0px 20px #ffffff28;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: .2s linear all
}
.card:hover {
   transform: scale(1.1);
}
.card h3 {
font-size: 1rem;
}

.card a:hover {
  text-decoration: underline !important;
}
.card a {
    font-size: 2rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 2.4rem;
}

.header h2 {
  font-size: 2.4rem;

}

.owner a {
  font-size: 1.5rem;
}

.git {
  color: black;
  font-size: 2.4rem;
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
}

.git:hover {
  color: black !important;
}

.cutoffText {
  font-size: 0.9rem;
  color: white
}

@keyframes breath {
  0% {
    background-size: 100% auto;
  }
  50% {
    background-size: 140% auto;
  }
  100% {
    background-size: 100% auto;
  }
}      

@media (max-width: 600px) {

  .cutoffText {
    display: none;
  }
}
@media (max-width: 997px) {
  .cardFrame {
    grid-template-columns: repeat(2, 1fr);    
    gap: 1px !important;
    display: flex;
    flex-direction: column;
  }
}

