.logo {
  height: 350px;
  width: 350px;
  stroke: white;
  fill-opacity: 0;
  position: relative;
  inset: 0;
  margin: auto;
  display: none;
}

.logo path {
  stroke-dasharray: 3500;
  stroke-dashoffset: 3500;

  animation: line-anim 5s ease forwards, fill 1s ease forwards 2s;
}

.box {
  height: 350px;
  height: 350px;
  background-color: black;
  position: relative;
  inset: 0;
  margin: auto;
}
@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {   
    fill: white
  }
}



@media screen and (max-width: 600px) {
  .logo path {
    fill: rgb(0, 0, 0);
  }
  .logo {
    width: 200px;
    height: 200px;
    margin-top: 7rem;
    display: block;
  }
}


 @media screen and (max-width: 1280px) {
  .logo {
    width: 50%;
    height: 50%;
  }
 }