.domain {
  position: fixed;
  left: 3rem;
  top: 1rem;
  z-index: 10121;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s linear all;
}
.domain:hover {
  color: white;
}
@media screen and (max-width: 600px) {
  .domain {
    font-size: 1rem;
    left: 1rem;
  }
}
