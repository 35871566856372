.about {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgb(0, 0, 0, 0.8)),
    url("https://s3.envato.com/files/212542380/Dark%20Background%20%20%20%2001_preview1%20.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: breath 50s linear infinite;
}

.left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.right {
  padding: 9rem;
  flex: 1;
}

.cube {
  flex: 1;
}

.title {
  font-weight: 400;
}

.sub {
  font-style: italic;
  margin: 20px 0px;
}

.description {
  color: white;
  font-weight: 300;
}

.cube {
  width: 200px;
  height: 200px;

  transform-style: preserve-3d !important;
  animation: rotate 30s linear infinite;
  border: none;
}

.container {
  position: absolute;
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  text-align: center;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box1 {
  transform: translateZ(100px);
}

.box2 {
  transform: rotateY(90deg) translateX(100px);
  transform-origin: right;
}

.box3 {
  transform: rotateY(180deg) translateZ(100px);
}

.box4 {
  transform: rotateY(-90deg) translateX(-100px);
  transform-origin: left;
}

.box5 {
  transform: rotateX(-90deg) translateY(-100px);
  transform-origin: top;
}

.box6 {
  transform: rotateX(90deg) translateY(100px);
  transform-origin: bottom;
}

/* Animating the elements */
@keyframes rotate {
  0%,
  100% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotateY(90deg) rotateZ(90deg);
  }
  40% {
    transform: rotateY(180deg) rotateZ(-90deg);
  }
  60% {
    transform: rotateY(270deg) rotateZ(90deg);
  }
  80% {
    transform: rotateY(360deg) rotateZ(-90deg);
  }
}
@keyframes breath {
  0% {
    background-size: 100% auto;
  }
  50% {
    background-size: 140% auto;
  }
  100% {
    background-size: 100% auto;
  }
}

@media screen and (max-width: 600px) {
  .about {
    background-size: 300% auto;
    animation: none;
  }

  .left {
    display: none;
  }
  .right {
    font-size: 75%;
    padding: 15%;
    margin-bottom: 35%;
  }
}

@media screen and (max-width: 1280px) {
  .right {
  font-size: 80%
  }
}