.projects {
  height: 100vh;
  width: 100vw;
  color: black;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center;
  animation: breath 50s linear infinite;
  display: flex;
}

.projectContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 70%;
  margin: 0 auto;
}
.left {
  flex: 1;
  background-color: rgb(0, 0, 0, 0.6);
  padding: 3rem 3rem;
  box-shadow: 0px 0px 20px #ffffff28;
  z-index: 2;
}

.right {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
}

.right img {
  object-fit: fill;
  height: 100%;
  width: 100%;
}
.title {
  font-size: 2rem;
  display: inline;
}

.subTitle {
  font-size: 1.25rem;
}

.header h4 {
  float: right;
  font-size: 1rem;
  margin: 0.5rem;
}

.description {
  margin-bottom: 3rem;
  color: white;
}

.demo {
  font-size: 0.9rem;
  float: right;
}
.demo span {
  color: white;
}
@keyframes breath {
  0% {
    background-size: 100% auto;
  }
  50% {
    background-size: 140% auto;
  }
  100% {
    background-size: 100% auto;
  }
}

@media screen and (max-width: 600px) {
  .projects {
    background-size: 300% auto;
    animation: none;
  }
  .right {
    display: none;
  }
  .left {
    position: relative;
    padding: 2rem;

    font-size: 75%;
    top: -50px;
  }
  .description {
    margin-bottom: 1.5rem;
  }
  .header h4 {
    display: none;
  }
  .subTitle {
    display: none;
  }
  .demo {
    display: none;
  }
  .button {
    display: inline;
  }
}


@media screen and (max-width: 1280px) {
  .left {
    padding: 2rem 2rem;
    font-size: 65%;
  }
    .demo {
 font-size: 100%;
  }
 .header h4 {
  font-size: 0.8rem;
 }
   .description {
    font-size: 0.7rem;
  }
}