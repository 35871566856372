@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0px);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}

.fa-down-long {
  position: absolute;
  font-size: 1.5rem;
  left: 49.625%;
  bottom: 1rem;
  z-index: 99999;
  animation: upDown 1.5s ease infinite;
}

@media screen and (max-width: 600px) {
  .fa-down-long {
    left: 49%;
  }
}
