.contact {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgb(0, 0, 0, 0.8)),
    url("https://t3.ftcdn.net/jpg/01/76/93/96/360_F_176939658_8zEA0BpFq1oYt9Nx19PRa8z87qk3Y8eW.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: breath 50s linear infinite;
}

.container {
  width: 100%;
  height: 100%;
}

.left {
  flex: 1;
  position: relative;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.title {
  font-size: 60px;
  width: 80%;
}

.description {
  font-weight: 200;
}
.description b {
  color: white;
}
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

button {
  float: right;
  background-color: rgb(227, 229, 196);
  color: black;
  font-size: 1rem;
  padding: 0.6rem 1.6rem;
  text-decoration: none;
  text-align: center;
  cursor: none;
  margin-top: 1rem;
}
input {
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  background: none;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: rgb(255, 255, 255);
}

textarea {
  width: 100%;
  background: none;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
}

textarea:focus,
input:focus {
  outline: none;
}

@media screen and (max-width: 600px) {
  .contact {
    flex-direction: column;
    overflow-x: hidden;
  }

  .right {
    padding: 50px 50px;
  }
  .left {
    width: 98.9%;
    margin-top: 30px;
  }

  .title {
    font-size: 30px;
  }

  .info {
    margin: 20px 0px;
    width: 100%;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    margin-top: 10px;
  }
}

@keyframes breath {
  0% {
    background-size: 100% auto;
  }
  50% {
    background-size: 140% auto;
  }
  100% {
    background-size: 100% auto;
  }
}

@media screen and (max-width: 600px) {
  .contact {
    background-size: 400%;
    animation: none;
    flex-direction: column;
    overflow-x: hidden;
  }

  .right {
    margin-bottom: 30%;
  }
  .left {
    display: none;
  }

  .title {
    font-size: 30px;
  }

  .info {
    margin: 20px 0px;
    width: 100%;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  .right button {
    margin-top: 10px;
    margin-left: 72%;
  }
}

@media screen and (max-width: 1500px) {
 .right {
      padding: 3rem;  
  }
}
@media screen and (max-width: 1280px) {
  .right {
      padding: 3rem;  font-size: 80%;
  }
    input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

}