.landing {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftWrapper {
  position: absolute;
}

.leftWrapper p {
  font-family: Gilroy ExtraBold, sans-serif;
  color: white;
}
.leftWrapper h1,
.leftWrapper p {
  margin: 0;
}
.rightWrapper {
  padding: 10%;
}

.rightWrapper img {
  height: 3rem;
  width: 3rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;

  border: 2px solid;
}

.rightWrapper h2 {
  color: white;
  font-size: 1.3rem;
}
.intro {
  font-size: 30px;
  font-weight: 300;
}

.name {
  font-size: 60px;
}

.description {
  height: 50px;
  font-size: 15px;
}
.video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  overflow: hidden;
}

.video:before {
  content: "";
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 600px) {
  .name {
    font-size: 3rem;
  }
  .landing {
    display: block;
  }
  .leftWrapper {
    margin-top: 5rem;
  }
  .video:before {
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  .rightWrapper {
    margin-top: 1rem;
    padding: 3rem;
  }

  .rightWrapper img {
    float: right;
  }
  .rightWrapper h2 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1280px) { 


}