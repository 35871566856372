.playBtn {
  position: fixed;
  right: 1.25rem;
  top: 1rem;
  z-index: 99999;
  font-size: 1.25rem;
  cursor: none;
}

.playBtn:hover {
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .playBtn {
    right: 1rem;
    top: auto;
    bottom: 1rem;
  }
}
