.socials {
  position: fixed;
  bottom: 0;
  z-index: 10000;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link:link,
.link:visited {
  text-decoration: none;
  font-size: 1.5rem;
  transition: all 0.3s ease-out;
}
.link:hover,
.link:active {
  color: grey !important;
}

@media screen and (max-width: 600px) {
  .socials {
    position: absolute;
    left: -20px;
    margin: 0;
  }
  .font {
    font-size: 16px;
  }
  .link:link,
  .link:visited {
    font-size: 1.25rem;
  }
}
