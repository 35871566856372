* {
  font-family: "Poppins";
  color: rgb(227, 229, 196);
  cursor: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: rgb(214, 214, 214);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100000000000;
  background: rgb(8, 8, 8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#circ {
  position: absolute;
  inset: 0;
  margin: auto;
  height: 1.25em;
  width: 1.25rem;
  border: 5px solid #ddd;
  border-left-color: transparent;
  border-radius: 10rem;
  animation: load 1s linear infinite;
}

@keyframes load {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
} */


#preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  background-color: black;
}
.square {
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: 3px;
  height: 3rem;
  margin-left: -225px;
  animation: sliding 3s ease infinite;
}

.square:nth-child(1n) {
  animation-delay: 0.3s;
}
.square:nth-child(2n) {
  animation-delay: 1s;
}
.square:nth-child(3n) {
  animation-delay: 0.7s;
}
.square:nth-child(5n) {
  animation-delay: 1.3s;
}
.square:nth-child(7n) {
  animation-delay: 1.5s;
}
.square:nth-child(11n) {
  animation-delay: 1.7s;
}
.square:nth-child(13n) {
  animation-delay: 2s;
}
.square:nth-child(17n) {
  animation-delay: 2.3s;
}
.square:nth-child(19n) {
  animation-delay: 2.5s;
}
.square:nth-child(23n) {
  animation-delay: 2.7s;
}
.square:nth-child(29n) {
  animation-delay: 3s;
}

@keyframes sliding {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(225px, 0);
  }
}

.pg-pips ul li a {
  background-color: rgb(227, 229, 196);
}

.primary-btn {
  display: inline-block;
  background-color: rgb(227, 229, 196);
  color: black;
  font-size: 1rem;
  padding: 0.6rem 1.6rem;
  text-decoration: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: 0.2s linear all !important;

}

.primary-btn:hover {
  opacity: 0.7 !important;
}
.outline {
  display: inline-block;
  background-color: transparent;
  color: rgb(227, 229, 196);
  font-size: 1rem;
  padding: 0.6rem 1.6rem;
  text-decoration: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}
.outline:hover {
  color: white;
}
/* .cursor2 {
  position: fixed;
  width: 2rem;
  height: 2rem;
  background: #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 2;
  mix-blend-mode: difference;
  transition: transform 0.2s;

  z-index: 10000;
} */

.cursor {
  position: fixed;
  width: 50px;
  height: 50px;
  border: 1px solid #c6c6c6;
  background-color: rgba(190, 190, 190, 0.479);
  opacity: 0.5;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.1s;
  z-index: 1000000;
    mix-blend-mode: difference;

}

.cursor2 {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.15s;
  z-index: 1000000;
}

.isHover:hover .cursor {
  transform: scale(1.5);
  background: red
}

@media screen and (max-width: 600px) {
  .cursor, .cursor2 {
    display: none;
  }
  .primary-btn,
  .outline {
    padding: 0.3rem 0.7rem;
    display: inline;
  }
  .outline {
    font-size: 0.8rem;
  }
}
